import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/templates/page.jsx";
import { Button } from '../../components/Button';
import { FormJoin } from '../../components/FormJoin';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="h2" components={components}>{`Mnohí z nás (sa) radi učia. Máš skúsenosti s prednášaním alebo s ním chceš začať?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Oblasti, o ktoré sa zaujímame, a o ktorých chceme šíriť informácie ďalej:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`sloboda`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`technológie a bezpečnosť`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`anonymita`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`decentralizácia`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`politické umenie`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`kryptomeny`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`ekológia`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`filozofia`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`a mnohé ďalšie (napíš nám aj ak máš návrh na tému, ktorá sem nespadá)`}</MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}>{`Ako sa zapojiť?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Ak máš skúsenosti s prednášaním alebo s ním chceš začať a zaujímaš sa o podobné myšlienky - ozvi sa nám.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Hľadáme aj ľudí do verejných diskusií alebo ich moderátorov. Našou snahou je, aby sa myšlienky neprezentovali len jednostranne, ale v zmysle “každá minca má dve strany”.`}</MDXTag>
      <FormJoin category="Pomoc s programom">
  Napíš nám svoju predstavu. Aká téma alebo kategória ťa zaujíma? Si z Košíc
  alebo tu vieš dochádzať?
      </FormJoin>
      <Button to="/zapoj-sa">Ďalšie možnosti ako sa zapojiť</Button>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      